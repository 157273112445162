import React, { useState, useContext, useEffect } from "react";
import CustomButton from "../../Component/CustomButton";
import axios from "axios";
import {
  BASE_URL,
  RESET_PASSWORD,
  SEND_FORGOT_PASSWORD_OTP,
  VALIDATE_JWT,
  VERIFY_FORGOT_PASSWORD_OTP,
} from "../../helpers/api_helper";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../helpers/types/contextType";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { toast } from "react-toastify";
import { Col, Label, Row } from "reactstrap";
import logo from "../../assets/images/logo.png";
import useBreakPoints from "../../hooks/useBreakPoints";
import OTPInput from "react-otp-input";
import CustomInputBoxWithIcon from "../../Component/CustomInputWithIcon";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { isTablet, isMobile } = useBreakPoints();
  const userContext = useContext(UserContext);
  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    otp: "",
    level: 0,
  });
  const [loading, setLoading] = useState(false);

  if (!userContext) {
    throw new Error("UserContext is undefined");
  }

  const { userId } = userContext;

  const handleSubmit = () => {
    // Validate email before making API call
    if (!forgotPasswordInputData.email || forgotPasswordInputData.email.trim() === "") {
      toast.error("Please enter your email address");
      return;
    }

    // Reset password
    setLoading(true);
    axios
      .post(
        BASE_URL + SEND_FORGOT_PASSWORD_OTP,
        {
          email: forgotPasswordInputData.email,
        },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      )
      .then((response: any) => {
        if (response.status === 200) {
          setForgotPasswordInputData({
            ...forgotPasswordInputData,
            level: 1,
          });
          toast.success("OTP sent successfully if email exists");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error while sending OTP");
        console.error("There was an error during forgot password!", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const jwt = GetCookies(CookieItems.JWT);
    const role = GetCookies(CookieItems.ROLE);

    if (
      (userId !== null && userId.jwt !== null && userId.role !== null) ||
      (jwt !== undefined && role !== undefined)
    ) {
      axios
        .post(BASE_URL + VALIDATE_JWT, { token: jwt })
        .then((response: any) => {
          console.log("response", response);
          if (response.data.user_id && response.status === 200) {
            navigate("/wallet");
          }
        })
        .catch((error: any) => {
          console.error("error", error);
          toast.error("Sign In expired, Please Log in again", {
            toastId: "sessionExp",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (
      forgotPasswordInputData.otp.length === 6 &&
      forgotPasswordInputData.level === 1
    ) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordInputData.otp]);

  const handleVerifyOtp = () => {
    if (forgotPasswordInputData.otp.length !== 6) {
      toast.error("Please enter a valid OTP");
    } else {
      setLoading(true);
      axios
        .post(
          BASE_URL + VERIFY_FORGOT_PASSWORD_OTP,
          {
            email: forgotPasswordInputData.email,
            otp: forgotPasswordInputData.otp,
          },
          {
            headers: {
              Authorization: userId?.jwt,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 200) {
            setForgotPasswordInputData({
              ...forgotPasswordInputData,
              level: 2,
            });
            toast.success("OTP verified successfully");
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.message === "cannot use old password") {
            toast.error("Cannot reset to old password");
          } else {
            toast.error("OTP verification failed");
          }
          console.error(
            "There was an error during forgot password otp verification",
            error
          );
          setLoading(false);
        });
    }
  };

  const validatePassword = (password: string) => {
    // Password should be at least 8 characters and contain alphabets, numerics, and a special character
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleUpdatePassword = () => {
    if (
      !forgotPasswordInputData.password ||
      !validatePassword(forgotPasswordInputData.password)
    ) {
      if (forgotPasswordInputData.password.length < 8) {
        toast.error("Password should be at least 8 characters");
      } else {
        toast.error(
          "Password should contain alphabets, numerics, and a special character"
        );
      }
    } else if (
      forgotPasswordInputData.password !==
      forgotPasswordInputData.confirmPassword
    ) {
      toast.error("Passwords do not match");
    } else {
      axios
        .post(
          BASE_URL + RESET_PASSWORD,
          {
            email: forgotPasswordInputData.email,
            otp: forgotPasswordInputData.otp,
            new_password: forgotPasswordInputData.password,
          },
          {
            headers: {
              Authorization: userId?.jwt,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 200) {
            setForgotPasswordInputData({
              level: 0,
              email: "",
              password: "",
              confirmPassword: "",
              otp: "",
            });
            toast.success("Password reset successfully");
            navigate("/login");
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Error while resetting password");
          console.error("There was an error during forgot password!", error);
          setLoading(false);
        });
    }
  };

  return (
    <div
      className="login-screen d-flex justify-content-center align-items-center w-100"
      style={{ position: "relative", height: "100vh" }}
    >
      <Row
        className="container"
        style={{
          marginBottom: isTablet ? 40 : 0,
          marginTop: isTablet ? 40 : 0,
          gap: isMobile ? 20 : 0,
        }}
      >
        <Col lg={6} md={5}>
          <div className="d-flex justify-content-start align-items-start flex-column">
            <img src={logo} alt="logo" height={43} />
            {!isMobile && (
              <>
                {" "}
                <p
                  style={{ fontSize: isTablet ? (isMobile ? 15 : 12) : 20 }}
                  className="mt-5 mb-0"
                >
                  Welcome to
                </p>
                <p
                  className="mt-2 mb-0 "
                  style={{
                    fontSize: isTablet ? (isMobile ? 30 : 25) : 44,
                    fontWeight: 700,
                  }}
                >
                  State-of-the-art Exchange
                </p>
                <p
                  className="mt-2 mb-0"
                  style={{
                    fontSize: isTablet ? (isMobile ? 15 : 12) : 20,
                    lineHeight: "24px",
                  }}
                >
                  Where Tokenized Sustainability Credits Meet Blockchain
                  Technology{" "}
                </p>
              </>
            )}
          </div>
        </Col>
        <Col lg={6} md={7}>
          {forgotPasswordInputData.level === 0 && (
            <div
              style={{
                background: "#111827",
                border: "1px solid #313d4f",

                borderRadius: 10,
                padding: 20,
              }}
            >
              <p style={{ color: "#fff", fontSize: 30, fontWeight: 700 }}>
                Reset Password
              </p>

              <Row className={`mt-4 `}>
                <Col lg={12} md={12}>
                  <Label style={{ fontSize: 15 }}>Email </Label>
                  <CustomInputBoxWithIcon
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.32324 7.00005L10.5232 11.65C11.5899 12.45 13.0565 12.45 14.1232 11.65L20.3232 7"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.3232 5H5.32324C4.21867 5 3.32324 5.89543 3.32324 7V17C3.32324 18.1046 4.21867 19 5.32324 19H19.3232C20.4278 19 21.3232 18.1046 21.3232 17V7C21.3232 5.89543 20.4278 5 19.3232 5Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    }
                    value={forgotPasswordInputData.email}
                    onchange={(e: any) =>
                      setForgotPasswordInputData({
                        ...forgotPasswordInputData,
                        email: e.target.value,
                      })
                    }
                    placeholder="Enter Email"
                    className="form-control"
                    type="email"
                  />
                </Col>

                <Col md={12} className="mt-4">
                  <CustomButton
                    placeholder="Send OTP"
                    style={{ width: "100%" }}
                    color="#00abff"
                    type="submit"
                    divclassName={`w-100`}
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={loading}
                  />
                </Col>
              </Row>
              <div className="text-center" style={{ cursor: 'pointer', marginTop: '10px', color: '#00abff', textDecoration: 'underline' }} onClick={() => navigate('/login')}>
                Return to Login
              </div>
            </div>

          )}
          {forgotPasswordInputData.level === 1 && (
            <div
              style={{
                background: "#111827",
                border: "1px solid #313d4f",

                borderRadius: 10,
                padding: 20,
              }}
            >
              <p style={{ color: "#fff", fontSize: 30, fontWeight: 700 }}>
                OTP VERIFICATION
              </p>

              <div className="text-center">
                <p style={{ color: "#fff", fontSize: 20, fontWeight: 700 }}>
                  Please Enter the OTP sent to your email.
                </p>
                <OTPInput
                  value={forgotPasswordInputData.otp}
                  onChange={(e: any) =>
                    setForgotPasswordInputData({
                      ...forgotPasswordInputData,
                      otp: e,
                    })
                  }
                  numInputs={6}
                  renderSeparator={<span></span>}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  inputStyle={{
                    width: "2rem",
                    height: "3rem",
                    margin: "0.5rem",
                    backgroundColor: "#323d4e",
                    color: "white",
                    border: "none",
                    borderRadius: 10,
                  }}
                  renderInput={(props) => <input {...props} />}
                />

                <CustomButton
                  placeholder="Verify OTP"
                  style={{ marginTop: 20 }}
                  color="#00abff"
                  type="submit"
                  divclassName={`w-100 text-center`}
                  onClick={() => {
                    handleVerifyOtp();
                  }}
                  disabled={loading}
                />
              </div>
            </div>
          )}
          {forgotPasswordInputData.level === 2 && (
            <div
              style={{
                background: "#111827",
                border: "1px solid #313d4f",

                borderRadius: 10,
                padding: 20,
              }}
            >
              <p style={{ color: "#fff", fontSize: 30, fontWeight: 700 }}>
                Update Password
              </p>

              <Row className={`mt-4 `}>
                <Col lg={12} md={12}>
                  <Label style={{ fontSize: 15 }}>Password </Label>
                  <CustomInputBoxWithIcon
                    passwordIcon={true}
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3896 14.5V16.5M7.38965 10.0288C7.86107 10 8.44224 10 9.18965 10H15.5896C16.337 10 16.9182 10 17.3896 10.0288M7.38965 10.0288C6.80133 10.0647 6.38394 10.1455 6.02768 10.327C5.46319 10.6146 5.00425 11.0735 4.71663 11.638C4.38965 12.2798 4.38965 13.1198 4.38965 14.8V16.2C4.38965 17.8802 4.38965 18.7202 4.71663 19.362C5.00425 19.9265 5.46319 20.3854 6.02768 20.673C6.66941 21 7.50949 21 9.18965 21H15.5896C17.2698 21 18.1098 21 18.7516 20.673C19.3161 20.3854 19.775 19.9265 20.0626 19.362C20.3896 18.7202 20.3896 17.8802 20.3896 16.2V14.8C20.3896 13.1198 20.3896 12.2798 20.0626 11.638C19.775 11.0735 19.3161 10.6146 18.7516 10.327C18.3953 10.1455 17.9779 10.0647 17.3896 10.0288M7.38965 10.0288V8C7.38965 5.23858 9.62823 3 12.3896 3C15.151 3 17.3896 5.23858 17.3896 8V10.0288"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    value={forgotPasswordInputData.password}
                    onchange={(e: any) =>
                      setForgotPasswordInputData({
                        ...forgotPasswordInputData,
                        password: e.target.value,
                      })
                    }
                    placeholder="Password"
                    className="form-control"
                    type="password"
                  />
                </Col>
                <Col lg={12} md={12} className="mt-3">
                  <Label style={{ fontSize: 15 }}>Confrim Password </Label>
                  <CustomInputBoxWithIcon
                    passwordIcon={true}
                    svgIcon={
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3896 14.5V16.5M7.38965 10.0288C7.86107 10 8.44224 10 9.18965 10H15.5896C16.337 10 16.9182 10 17.3896 10.0288M7.38965 10.0288C6.80133 10.0647 6.38394 10.1455 6.02768 10.327C5.46319 10.6146 5.00425 11.0735 4.71663 11.638C4.38965 12.2798 4.38965 13.1198 4.38965 14.8V16.2C4.38965 17.8802 4.38965 18.7202 4.71663 19.362C5.00425 19.9265 5.46319 20.3854 6.02768 20.673C6.66941 21 7.50949 21 9.18965 21H15.5896C17.2698 21 18.1098 21 18.7516 20.673C19.3161 20.3854 19.775 19.9265 20.0626 19.362C20.3896 18.7202 20.3896 17.8802 20.3896 16.2V14.8C20.3896 13.1198 20.3896 12.2798 20.0626 11.638C19.775 11.0735 19.3161 10.6146 18.7516 10.327C18.3953 10.1455 17.9779 10.0647 17.3896 10.0288M7.38965 10.0288V8C7.38965 5.23858 9.62823 3 12.3896 3C15.151 3 17.3896 5.23858 17.3896 8V10.0288"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    value={forgotPasswordInputData.confirmPassword}
                    onchange={(e: any) =>
                      setForgotPasswordInputData({
                        ...forgotPasswordInputData,
                        confirmPassword: e.target.value,
                      })
                    }
                    placeholder="Confirm Password"
                    className="form-control"
                    type="password"
                  />
                </Col>

                <Col md={12} className="mt-4">
                  <CustomButton
                    placeholder="Update Password"
                    style={{ width: "100%" }}
                    color="#00abff"
                    type="submit"
                    divclassName={`w-100`}
                    onClick={() => {
                      handleUpdatePassword();
                    }}
                    disabled={loading}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>

      {!isTablet && (
        <div
          className=""
          style={{ position: "absolute", left: 150, bottom: 10 }}
        >
          <p
            className=""
            style={{
              fontSize: 14,
              color: "#fff",
              fontWeight: 600,
              lineHeight: "19.1px",
              letterSpacing: "0.3px",
            }}
          >
            © 2024 by Cenzex. LEGAL TERMS PRIVACY
          </p>
        </div>
      )}
    </div>
  );
};
