import React, { useContext, useEffect, useState } from "react";

import wallet from "../assets/svg/wallet.svg";
import Trading from "../assets/svg/Trade.svg";
import TradePair from "../assets/svg/TradePair.svg";
import MyOrder from "../assets/svg/MyOrder.svg";
import CorporateRequest from "../assets/svg/CorporateRequest.svg";
import MyCreditRequest from "../assets/svg/MyCreditRequest.svg";
import Transaction from "../assets/svg/transaction.svg";
import CreditRequest from "../assets/svg/CreditRequest.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../helpers/types/contextType";
import { AccessControl, Role } from "../helpers/types/role";
import { CookieItems, GetCookies } from "../helpers/cookies_helper";

const ClosedSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId } = userContext;

  useEffect(() => {
    const jwt = GetCookies(CookieItems.JWT);
    setIsAuthenticated(!!jwt && !!userId);
  }, [userId]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <ul
      className="custom-closed-sidebar"
      style={{
        listStyle: "none",
        padding: "0",
        margin: "0",
        width: 70,
        gap: "12px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#273142",
        position: "sticky",
        height: 725,
        top: 0,
      }}
    >
      {userId !== null &&
        userId.role !== null &&
        AccessControl.wallet.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              marginTop: 15,
              cursor: "pointer",
              background:
                location?.pathname === "/wallet" ? "#00abff" : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/wallet")}
          >
            <img src={wallet} alt="wallet" />
          </li>
        )}
      {userId !== null &&
        userId.role !== null &&
        AccessControl.trading.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname?.split("/")[1] === "trading"
                  ? "#00abff"
                  : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/trading/1")}
          >
            <img src={Trading} alt="Trading" />
          </li>
        )}

      {userId !== null &&
        userId.role !== null &&
        AccessControl.explore.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname === "/explore" ? "#00abff" : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/explore")}
          >
            <img src={TradePair} alt="TradePair" />
          </li>
        )}

      {userId !== null &&
        userId.role !== null &&
        AccessControl.myorders.includes(userId.role as Role) && (
          <li
            style={{
              // marginTop: 30,
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname === "/myorders" ? "#00abff" : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/myorders")}
          >
            <img src={MyOrder} alt="MyOrder" />
          </li>
        )}

      {userId !== null &&
        userId.role !== null &&
        AccessControl.myorders.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname === "/transactions"
                  ? "#00abff"
                  : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/transactions")}
          >
            <img src={Transaction} alt="Transaction" />
          </li>
        )}

      {userId !== null &&
        userId.role !== null &&
        AccessControl.myrequests.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname === "/myrequests"
                  ? "#00abff"
                  : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/myrequests")}
          >
            <img src={MyCreditRequest} alt="MyCreditRequest" />
          </li>
        )}
      {userId !== null &&
        userId.role !== null &&
        AccessControl.admin.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              marginTop: 30,
              cursor: "pointer",
              background:
                location?.pathname === "/credit-request-management"
                  ? "#00abff"
                  : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/credit-request-management")}
          >
            <img src={CreditRequest} alt="CreditRequest" />
          </li>
        )}
      {userId !== null &&
        userId.role !== null &&
        AccessControl.corprequestmngmt.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname === "/corporate-request-management"
                  ? "#00abff"
                  : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/corporate-request-management")}
          >
            <img src={CorporateRequest} alt="CorporateRequest" />
          </li>
        )}
      {userId !== null &&
        userId.role !== null &&
        AccessControl.admin.includes(userId.role as Role) && (
          <li
            style={{
              marginLeft: 8.52,
              padding: "14px 15.5px 14px 15.98px",
              cursor: "pointer",
              background:
                location?.pathname === "/addassets" ? "#00abff" : "transparent",
              borderRadius: "4px 0px 0px 4px",
            }}
            onClick={() => navigate("/addassets")}
          >
            <img src={MyCreditRequest} alt="MyCreditRequest" />{" "}
          </li>
        )}
    </ul>
  );
};

export default ClosedSidebar;
