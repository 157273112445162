import React from "react";

import { useContext, useEffect, useRef, useState } from "react";
import CustomButton from "../../Component/CustomButton";
import { TradePair, UserData } from "../../helpers/types/contextType";
import { useNavigate } from "react-router-dom";
import { SocketConectionContext } from "../../hooks/SocketConectionContext/SocketConectionContext";
import { BuySellType, PendingOrderType } from "../../helpers/types/BuySellType";
import axios from "axios";
import {
  BASE_URL,
  CANCELORDERBYORDERID,
  CANCEL_OPEN_ORDER,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { Table } from "reactstrap";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";
import { Typography } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from "@mui/material";

interface OpenOrdersProps {
  userID: UserData | null;
  tradePairID: TradePair | undefined;
  resizeMode?: boolean;
  setRefreshNumbers?: React.Dispatch<React.SetStateAction<string>>;
}

interface CancelOrderResponse {
  success: boolean;
  message: string;
  error?: string;
}

const OpenOrdersContainer = (props: OpenOrdersProps) => {
  const navigate = useNavigate();
  const socketConnectionContext = useContext(SocketConectionContext);
  if (!socketConnectionContext) {
    throw new Error("socketConnectionContext is undefined");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { webSocketService, setWebSocketService } = socketConnectionContext;
  const [pendingOrderData, setPendingOrderData] = useState<
    PendingOrderType[] | null
  >([]);

  const [loading, setLoading] = useState({
    cancel: false,
    "buy-sell": false,
  });

  const [containerHeight, setContainerHeight] = useState("100%");
  const containerRef = useRef<HTMLDivElement>(null);

  const [orderToCancel, setOrderToCancel] = useState<{ orderId?: string, tradePairId?: number, orderSide?: string, isAllOrders: boolean } | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [dontAskAgain, setDontAskAgain] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerHeight(`${entry.contentRect.height - 50}px`);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleCancelOrder = async (orderId?: string, tradePairId?: number, orderSide?: string, isAllOrders: boolean = false) => {
    if (dontAskAgain) {
      if (isAllOrders) {
        await executeCancelAllOrders();
      } else {
        await executeCancelOrder(orderId!, tradePairId!, orderSide!);
      }
      return;
    }

    setOrderToCancel({ orderId, tradePairId, orderSide, isAllOrders });
    setShowConfirmDialog(true);
  };

  const handleConfirmCancel = async () => {
    try {
      if (orderToCancel?.isAllOrders) {
        await executeCancelAllOrders();
      } else {
        await executeCancelOrder(
          orderToCancel!.orderId!,
          orderToCancel!.tradePairId!,
          orderToCancel!.orderSide!
        );
      }
    } finally {
      setShowConfirmDialog(false);
      setOrderToCancel(null);
    }
  };

  const executeCancelOrder = async (orderId: string, tradePairId: number, orderSide: string) => {
    try {
      setLoading(prev => ({ ...prev, cancel: true }));

      if (!props.userID?.jwt) {
        throw new Error('Authentication token missing');
      }

      const response = await axios.post<CancelOrderResponse>(
        `${BASE_URL}${CANCELORDERBYORDERID}`,
        {
          order_id: orderId,
          trade_pair_id: tradePairId,
          order_side: orderSide,
        },
        {
          headers: {
            Authorization: props.userID.jwt,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        toast.success('Order cancelled successfully');
        props.setRefreshNumbers?.(Math.random().toString(36).substring(2, 15));
      } else {
        throw new Error('Failed to cancel order');
      }
    } catch (error: any) {
      console.error('Error cancelling order:', error);

      if (error?.response?.status === 401) {
        toast.error('Session expired, please login again', { toastId: 'sessionExp' });
        localStorage.clear();
        navigate('/logout');
        return;
      }

      toast.error(error?.response?.data?.message || error.message || 'Failed to cancel order');
    } finally {
      setLoading(prev => ({ ...prev, cancel: false }));
    }
  };

  const executeCancelAllOrders = async () => {
    try {
      setLoading(prev => ({ ...prev, cancel: true }));

      if (!props.userID?.jwt) {
        throw new Error('Authentication token missing');
      }

      if (!props.tradePairID?.id) {
        throw new Error('Trade pair ID is required');
      }

      const response = await axios.post<CancelOrderResponse>(
        `${BASE_URL}${CANCEL_OPEN_ORDER}`,
        {
          user_id: null,
          trade_pair_id: props.tradePairID.id
        },
        {
          headers: {
            Authorization: props.userID.jwt,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        toast.success('All orders cancelled successfully');
        props.setRefreshNumbers?.(Math.random().toString(36).substring(2, 15));
      } else {
        throw new Error('Failed to cancel all orders');
      }
    } catch (error: any) {
      console.error('Error cancelling all orders:', error);

      if (error?.response?.status === 401) {
        toast.error('Session expired, please login again', { toastId: 'sessionExp' });
        localStorage.clear();
        navigate('/logout');
        return;
      }

      toast.error(error?.response?.data?.message || error.message || 'Failed to cancel all orders');
    } finally {
      setLoading(prev => ({ ...prev, cancel: false }));
    }
  };

  useEffect(() => {
    if (webSocketService !== null) {
      // Define the message handler
      const handleMessage = (newData: any) => {
        // console.log("Recent Trades", newData, newData.recent_trades);
        if (newData && newData.open_orders !== undefined) {
          setPendingOrderData(newData.open_orders);
        }
      };
      webSocketService.setOnMessageHandler(handleMessage);
    }
  }, [webSocketService]);

  console.log("pendingOrders", pendingOrderData);
  return (
    <>
      {props.resizeMode ? (
        <div
          ref={containerRef}
          style={{
            background: "#273142",
            padding: 10,
            border: "1px solid #313d4f",

            borderRadius: 5,
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: 24, lineHeight: "20px", fontWeight: 700 }}
          >
            Open Orders
          </Typography>

          <div className="d-flex justify-content-end">
            <CustomButton
              placeholder="Cancel All"
              color="#e94953"
              divclassName={"m-0"}
              type="submit"
              style={{ textWrap: "nowrap" }}
              onClick={() => handleCancelOrder(undefined, undefined, undefined, true)}
              disabled={loading.cancel || !pendingOrderData?.length}
            />
          </div>

          <div
            className="mt-3"
            style={{
              height: containerHeight,

              overflow: "auto",
            }}
          >
            <Table className={``} responsive bordered={false}>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 200,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderRadius: "10px 0px 0px 10px",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "1px solid #444e5d",
                      borderRight: "none",
                    }}
                  >
                    Date - Time
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {" "}
                    Value
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {" "}
                    Filled
                  </th>
                  <th
                    className="d-flex  justify-content-center align-items-center w-100"
                    style={{
                      width: "25%",
                      minWidth: 200,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "1px solid #444e5d",
                      borderRadius: "0px 10px 10px 0px",
                    }}
                  >
                    Action &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingOrderData?.map((item: any) => (
                  <tr
                    style={{
                      color:
                        item.order_side === BuySellType.SELL.toLowerCase()
                          ? "#ff656d"
                          : "#35e3d1",
                    }}
                  >
                    <td
                      style={{
                        background: "#1b2431",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {UnixUTCtoLocalTime(
                        Number(item.order_placement_time),
                        "D MMMM YYYY h:mm:ssAA"
                      )}{" "}
                    </td>
                    <td
                      style={{
                        background: "#1b2431",
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {item.price.toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#1b2431",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                        textTransform: "capitalize",
                      }}
                    >
                      {item.amount.toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#1b2431",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {(item.amount * item.price).toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#1b2431",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {item.filled.toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#1b2431",
                        paddingLeft: 15,
                        color: "white",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      <CustomButton
                        placeholder="Cancel"
                        onClick={() => handleCancelOrder(
                          String(item.id),
                          item.trade_pair_id,
                          item.order_side
                        )}
                        color="#ff4c54"
                        disabled={loading.cancel || item.status === "canceled" || item.status === "fulfilled"}
                        style={{
                          width: "10px",
                          height: "22px",
                          fontSize: "0.9em",
                          marginRight: "10px",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div
          ref={containerRef}
          style={{
            background: "#273142",
            padding: 10,
            borderRadius: 5,
          }}
        >
          <div>
            <div className="d-flex justify-content-end mb-2">
              <CustomButton
                placeholder="Cancel All"
                color="#e94953"
                divclassName={"m-0"}
                type="submit"
                style={{ textWrap: "nowrap" }}
                  onClick={() => handleCancelOrder(undefined, undefined, undefined, true)}
                  disabled={loading.cancel || !pendingOrderData?.length}
              />
            </div>

            <Table className={``} responsive bordered={false}>
              <thead>
                <tr className="text-center">
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "1px solid #444e5d",
                      borderRight: "none",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  >
                    Date - Time
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    Price (ATR)
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    Amount (VCC)
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {" "}
                    Value (ATR)
                  </th>
                  <th
                    style={{
                      width: "25%",
                      minWidth: 100,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {" "}
                    Filled
                  </th>
                  <th
                    className="d-flex  justify-content-center align-items-center w-100"
                    style={{
                      width: "25%",
                      minWidth: 150,
                      background: "#313c4d",
                      paddingLeft: 15,
                      color: "white",
                      borderTop: "1px solid #444e5d",
                      borderBottom: "1px solid #444e5d",
                      borderLeft: "none",
                      borderRight: "1px solid #444e5d",
                      borderRadius: "0px 10px 10px 0px",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody style={{ border: "1px solid #444e5d" }}>
                {pendingOrderData?.map((item: any) => (
                  <tr
                    className="text-center"
                    style={{
                      color:
                        item.order_side === BuySellType.SELL.toLowerCase()
                          ? "#ff656d"
                          : "#35e3d1",
                    }}
                  >
                    <td
                      style={{
                        background: "#273142",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {UnixUTCtoLocalTime(
                        Number(item.order_placement_time),
                        "D MMMM YYYY h:mm:ssA"
                      )}{" "}
                    </td>
                    <td
                      style={{
                        background: "#273142",
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {item.price.toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#273142",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                        textTransform: "capitalize",
                      }}
                    >
                      {item.amount.toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#273142",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {(item.amount * item.price).toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#273142",
                        paddingLeft: 15,
                        color:
                          item.order_side === BuySellType.SELL.toLowerCase()
                            ? "#ff656d"
                            : "#35e3d1",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      {item.filled.toFixed(2)}
                    </td>
                    <td
                      style={{
                        background: "#273142",
                        paddingLeft: 15,
                        color: "white",
                        fontSize: 12,
                        border: "none",
                      }}
                    >
                      <CustomButton
                        placeholder="Cancel"
                        onClick={() => handleCancelOrder(
                          String(item.id),
                          item.trade_pair_id,
                          item.order_side
                        )}
                        color="#ff4c54"
                        disabled={loading.cancel || item.status === "canceled" || item.status === "fulfilled"}
                        style={{
                          width: "10px",
                          height: "22px",
                          fontSize: "0.9em",
                          marginRight: "10px",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      <Dialog
        open={showConfirmDialog}
        onClose={() => !loading.cancel && setShowConfirmDialog(false)}
        aria-labelledby="cancel-order-dialog"
      >
        <DialogTitle>
          {orderToCancel?.isAllOrders ? 'Cancel All Orders' : 'Cancel Order'}
        </DialogTitle>
        <DialogContent>
          <p>Are you sure you want to cancel {orderToCancel?.isAllOrders ? 'all orders' : 'this order'}?</p>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontAskAgain}
                onChange={(e) => setDontAskAgain(e.target.checked)}
                disabled={loading.cancel}
              />
            }
            label="Don't ask again"
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            placeholder="Cancel"
            onClick={() => setShowConfirmDialog(false)}
            color="#6c757d"
            disabled={loading.cancel}
          />
          <CustomButton
            placeholder="Confirm"
            onClick={handleConfirmCancel}
            color="#e94953"
            disabled={loading.cancel}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OpenOrdersContainer;
