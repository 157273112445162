import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../assets/images/logo.png";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../helpers/types/contextType";
import useBreakPoints from "../hooks/useBreakPoints";
import { Sidebar } from "./Sidebar";
import CustomButton from "../Component/CustomButton";

const Header = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useBreakPoints();
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId, resizeMode, handleResizeModeChange, handleLayoutGridChange } =
    userContext;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log("location?.pathname?.", location?.pathname?.split("/"));

  return (
    <>
      {userId?.jwt && (
        <div
          style={{ backgroundColor: "#212a3a", color: "white", padding: 10 }}
        >
          <div className="d-flex align-items-center justify-content-between">
            {!userId?.jwt && (
              <div className="flex items-center gap-2">
                <img src={logo} height={30} alt="logo" />
              </div>
            )}
            {isMobile && (
              <div className="flex items-center gap-2">
                <img src={logo} height={25} alt="logo" />
              </div>
            )}

            {userId?.jwt && (
              <>
                <div
                  className={`${
                    isMobile
                      ? "flex-row-reverse	justify-content-end "
                      : " justify-content-center"
                  } d-flex  align-items-center gap-4`}
                >
                  <Sidebar />

                  {/* {!isMobile && (
                    <InputGroup
                      size="sm"
                      style={{ marginLeft: isMobile ? 0 : 15 }}
                    >
                      <InputGroupText
                        style={{
                          borderRadius: "20px 0px 0px 20px",
                          backgroundColor: "#323d4e",
                          color: "white",
                          border: "none",
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Input
                        style={{
                          width: isMobile ? 80 : 250,
                          borderRadius: "0px 20px 20px 0px",
                          backgroundColor: "#323d4e",
                          color: "white",
                          border: "none",
                        }}
                        type="text"
                        placeholder="Search"
                      />
                    </InputGroup>
                  )}

                  {isMobile && <i className="fa fa-search"></i>} */}
                </div>

                {!isTablet && (
                  <div
                    className={`d-flex justify-content-center align-items-center gap-4 `}
                  >
                    {location?.pathname?.split("/")?.[1] === "trading" && (
                      <div className="d-flex justify-content-start align-items-center  gap-4 ">
                        <p
                          className={`mb-0`}
                          style={{ width: isMobile ? "60%" : "auto" }}
                        >
                          Resize Mode{" "}
                        </p>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              handleResizeModeChange(true);
                            }}
                            style={{
                              border: resizeMode
                                ? "1px solid #00abff"
                                : "1px solid #444e5d",
                              background: resizeMode ? "#00abff" : "#323d4e",
                              fontSize: 12,
                              padding: "7px 13px",
                              color: "white",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                          >
                            YES
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              handleResizeModeChange(false);
                            }}
                            style={{
                              border: !resizeMode
                                ? "1px solid #00abff"
                                : "1px solid #444e5d",
                              background: !resizeMode ? "#00abff" : "#323d4e",
                              fontSize: 12,
                              padding: "7px 13px",
                              color: "white",
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          >
                            NO
                          </button>
                        </div>
                      </div>
                    )}
                    {location?.pathname?.split("/")?.[1] === "trading" &&
                      resizeMode && (
                        <CustomButton
                          placeholder="Reset"
                          color="#e94953"
                          divclassName={"m-0"}
                          type="submit"
                          style={{ textWrap: "nowrap" }}
                          onClick={() => {
                            handleLayoutGridChange([]);
                            localStorage.removeItem("layout");

                            window.location.reload();
                          }}
                        />
                      )}

                    {/* <span className="custom-fa-style" data-count="28">
                      <i
                        style={{ fontSize: 24 }}
                        className="fa fa-bell fa-inverse"
                      ></i>
                    </span> */}
                  </div>
                )}
                {!isMobile && (
                  <div className="cust-nav" ref={menuRef}>
                    <div className="profile">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowMenu(!showMenu)}
                        className="user d-flex justify-content-center align-items-center gap-2"
                      >
                        <AccountCircleRoundedIcon
                          style={{ fontSize: 30, marginTop: -6 }}
                        />
                      </div>
                    </div>
                    <div className={`menu ${showMenu ? "active" : ""}`}>
                      <ul className="mb-0">
                        <li>
                          <Link to="/profile">
                            <i className="fa fa-user"></i>&nbsp;Profile
                          </Link>
                        </li>
                        <li>
                          <Link to="/logout">
                            <i className="fa fa-sign-out"></i>&nbsp;Log Out
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
