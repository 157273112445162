import React, { useContext, useEffect, useState } from "react";
// import CustomButton from "../../Component/CustomButton";
import useBreakPoints from "../../hooks/useBreakPoints";
import { useNavigate, useParams } from "react-router-dom";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import {
  TradePair,
  // TradingData,
  UserContext,
} from "../../helpers/types/contextType";
import { TokenIDType } from "../../helpers/types/WalletType";
import axios from "axios";
import {
  BASE_URL,
  GET_ALL_TRADE_PAIRS_EP,
  GET_ALL_TRADE_PAIR_CATEGORY,
  GET_OHLCV,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import CustomDropdown from "../../Component/CustomDropdown";
import { Col, Label, Row } from "reactstrap";
import RecentTrades from "./RecentTrades";
import SocketConectionProvider from "../../hooks/SocketConectionContext/SocketConectionProvider";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../hooks/ErrorFallback/ErrorFallback";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChartConatiner } from "./ChartContainer";
import MarketDepthContainer from "./MarketDepthContainer";
import BuySellContainer from "./BuySellContainer";
import { BuySellType } from "../../helpers/types/BuySellType";
import OpenOrdersContainer from "./OpenOrdersContainer";
import OrderBookConatiner from "./OrderBookConatiner";
import TradePairSelectorComponent from "./TradePairSelectorComponent";

const componentArray = [
  { label: "Market Depth", value: "Market Depth" },
  { label: "Trading Chart", value: "Trading Chart" },
  { label: "Recent Trades", value: "Recent Trades" },
  { label: "Order Book", value: "Order Book" },
];

const Trading = () => {
  const { isMobile, isTablet } = useBreakPoints();
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const { tradePairId } = useParams();

  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext1 is undefined");
  }

  const {
    userId,
    tradingData,
    setTradingData,
    selectedTradePair,
    setSelectedTradePair,
  } = userContext;

  console.log("selectedTradePair", selectedTradePair);

  const [ohlcvs, setOHLCVs] = useState<
    | {
        close: number;
        high: number;
        low: number;
        open: number;
        time: number;
        volume: number;
      }[]
    | null
  >(null);

  const [tokenIDs, settokenIDs] = useState<TokenIDType[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<{
    label: string;
    value: string;
  }>({ label: "Market Depth", value: "Market Depth" });

  console.log(selectedComponent, "selectedComponent");
  const [selectedRegion, setSelectedRegion] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [refreshNumbers, setRefreshNumbers] = useState<string>("");

  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [selectedBuySellMode, setSelectedBuySellMode] = useState<BuySellType>(
    BuySellType.BUY
  );

  useEffect(() => {
    if (tradePairId && tradingData) {
      const tradePair = tradingData.find(
        (pair) => pair.value.id === parseInt(tradePairId)
      );
      if (tradePair) {
        setSelectedTradePair({
          label: tradePair?.label || "",
          value: tradePair?.value,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradePairId, tradingData, setTradingData]);

  console.log({
    tradingData,
    tokenIDs,
    selectedRegion,
    options,
    selectedBuySellMode,
    ohlcvs,
  });

  const getAllTradePairs = async () => {
    const requestBody: any = {
      headers: {
        Authorization: jwt,
      },
    };

    // Add region to the request body if selectedRegion is specified and not 'ALL'
    if (selectedRegion && selectedRegion.value !== "ALL") {
      requestBody.region = selectedRegion.value;
    }

    await axios
      .post(BASE_URL + GET_ALL_TRADE_PAIRS_EP, requestBody)
      .then((response: any) => {
        if (response.data === null) {
          setTradingData([]);
        } else {
          console.log("trading_page_feeder", response.data);
          if (tradePairId) {
            if (
              response.data.find(
                (tradePair: TradePair) => tradePair.id === Number(tradePairId)
              )
            ) {
              setTradingData(
                response.data.map((item: any) => ({
                  label: item.name,
                  value: item,
                }))
              );

              setSelectedTradePair({
                label: response.data.find(
                  (tradePair: TradePair) => tradePair.id === Number(tradePairId)
                )?.name,
                value: response.data.find(
                  (tradePair: TradePair) => tradePair.id === Number(tradePairId)
                ),
              });
              if (options.length <= 0) {
                getAllOHLC(
                  response.data.find(
                    (tradePair: TradePair) =>
                      tradePair.id === Number(tradePairId)
                  )?.id
                );
              }
            } else {
              setTradingData(
                response.data.map((item: any) => ({
                  label: item.name,
                  value: item,
                }))
              );
              navigate(`/trading/${response.data[0]?.id}`, { replace: true });
              setSelectedTradePair({
                label: response.data[0]?.name,
                value: response.data[0],
              });
              if (options.length <= 0) {
                getAllOHLC(response.data[0]?.id);
              }
            }
          } else {
            setTradingData(
              response.data.map((item: any) => ({
                label: item.name,
                value: item,
              }))
            );
            setSelectedTradePair({
              label: response.data[0]?.name,
              value: response.data[0],
            });

            navigate(`/trading/${response.data[0]?.id}`, { replace: true });

            if (options.length <= 0) {
              getAllOHLC(response.data[0]?.id);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("There was an error!", error);
        }
      });
  };

  const getAllRegionsByCategory = async () => {
    try {
      const response = await axios.post(
        BASE_URL + GET_ALL_TRADE_PAIR_CATEGORY,
        {}
      );
      const data = await response.data;
      if (data.length > 0) {
        setOptions(data);

        setSelectedRegion(data[0]);
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Error posting request:", error);
      }
    }
  };

  const getAllOHLC = async (tradePairId: number) => {
    await axios
      .post(
        BASE_URL + GET_OHLCV,
        { trade_pair_id: tradePairId || 0 },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response: any) => {
        getAllRegionsByCategory();
        if (
          response.data !== null &&
          response.data !== undefined &&
          response.data.length > 0
        ) {
          setOHLCVs(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting ohlvc", error);
          // toast.error("Error while getting ohlvc");
        }
      });
  };

  useEffect(() => {
    getAllTradePairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  console.log("tradingdata", tradingData);

  return (
    <SocketConectionProvider selectedComponent={selectedComponent.value}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, componentStack) => {
          console.error("error in trading page", error);
          console.error("componentStack", componentStack);
        }}
      >
        <div className="mt-3 p-4 pt-0">
          <Row className="mt-4">
            <Col md={8}>
              {isMobile && (
                <>
                  <Row className={`${isTablet ? "gap-3" : ""}`}>
                    <Col md={12}>
                      <TradePairSelectorComponent
                        selectedRegion={selectedRegion?.value || ""}
                        allTradePairs={
                          tradingData?.map((er) => er?.value) || []
                        }
                        selectedTradePair={selectedTradePair}
                        setSelectedTradePair={setSelectedTradePair}
                      />
                    </Col>
                    <Col md={12}>
                      <Label className="mb-0">Select Regions</Label>

                      <CustomDropdown
                        options={options}
                        value={selectedRegion}
                        onChange={(e: any) => {
                          setSelectedRegion(e);
                        }}
                        isDisabled={false}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3 ">
                    <Col md={12}>
                      <TradePairSelectorComponent
                        selectedRegion={selectedRegion?.value || ""}
                        allTradePairs={
                          tradingData?.map((er) => er?.value) || []
                        }
                        selectedTradePair={selectedTradePair}
                        setSelectedTradePair={setSelectedTradePair}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <div>
                <div
                  style={{
                    background: "#323d4e",
                    borderRadius: 5,
                    padding: 12,
                  }}
                  className={`d-flex justify-content-between align-items-center w-100 mt-2 ${
                    isMobile ? "flex-row gap-2 " : "gap-4"
                  }`}
                >
                  <div
                    style={{
                      borderRight: "1px solid white",
                      marginTop: 0,
                      paddingTop: 0,
                      width: "20%",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-start gap-2">
                      <p
                        className="mb-0"
                        style={{
                          fontSize: isMobile ? 12 : 20,
                          fontWeight: 700,
                        }}
                      >
                        {ohlcvs ? ohlcvs[0].low.toFixed(2) : "..."}{" "}
                      </p>
                    </div>

                    <span
                      style={{ fontSize: isMobile ? 10 : 16, fontWeight: 400 }}
                    >
                      Lowest 24h
                    </span>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid white",
                      marginTop: 0,
                      paddingTop: 0,
                      width: "20%",
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{ fontSize: isMobile ? 12 : 20, fontWeight: 700 }}
                    >
                      {ohlcvs ? ohlcvs[0].close.toFixed(2) : "..."}
                    </p>
                    <span
                      style={{ fontSize: isMobile ? 10 : 16, fontWeight: 400 }}
                    >
                      Last Price
                    </span>
                  </div>

                  <div
                    style={{
                      borderRight: "1px solid white",
                      marginTop: 0,
                      paddingTop: 0,
                      width: "20%",
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{ fontSize: isMobile ? 12 : 20, fontWeight: 700 }}
                    >
                      {ohlcvs ? ohlcvs[0].volume.toFixed(2) : "..."}
                    </p>
                    <span
                      style={{ fontSize: isMobile ? 10 : 16, fontWeight: 400 }}
                    >
                      24h Volume{" "}
                    </span>
                  </div>

                  <div
                    style={{
                      borderRight: "1px solid white",
                      marginTop: 0,
                      paddingTop: 0,
                      width: "20%",
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{ fontSize: isMobile ? 12 : 20, fontWeight: 700 }}
                    >
                      {ohlcvs ? ohlcvs[0].high.toFixed(2) : "..."}
                    </p>
                    <span
                      style={{ fontSize: isMobile ? 10 : 16, fontWeight: 400 }}
                    >
                      Highest 24h{" "}
                    </span>
                  </div>

                  <div
                    style={{
                      width: "20%",
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{ fontSize: isMobile ? 12 : 20, fontWeight: 700 }}
                    >
                      {ohlcvs
                        ? (
                            ((ohlcvs[0].high - ohlcvs[0].low) /
                              ohlcvs[0].high) *
                            100
                          ).toFixed(2)
                        : "...      "}
                      %{" "}
                    </p>
                    <span
                      style={{ fontSize: isMobile ? 10 : 16, fontWeight: 400 }}
                    >
                      Change{" "}
                    </span>
                  </div>
                </div>
              </div>

              {isMobile && (
                <Row className="mt-3 mb-4">
                  <Col md={12}>
                    <Label className="mb-0">Select Menu</Label>
                    <CustomDropdown
                      options={componentArray}
                      value={selectedComponent}
                      onChange={(e: any) => {
                        setSelectedComponent(e);
                      }}
                      isDisabled={false}
                    />
                  </Col>
                </Row>
              )}

              {(!isMobile || selectedComponent.value === "Market Depth") && (
                <Accordion
                  sx={{
                    background: " #273142",
                    border: " 1px solid #313d4f",
                    borderRadius: 2,
                  }}
                  defaultExpanded={true}
                  className="mt-3"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      height: "20%",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: 24,
                        lineHeight: "20px",
                        fontWeight: 700,
                      }}
                    >
                      Market Depth{" "}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ minHeight: "100%" }}>
                    {tradingData !== null &&
                      userId !== null &&
                      selectedTradePair !== undefined && (
                        <MarketDepthContainer
                          traidID={selectedTradePair?.value?.id || 0}
                        />
                      )}
                  </AccordionDetails>
                </Accordion>
              )}

              {(!isMobile || selectedComponent.value === "Trading Chart") && (
                <Accordion
                  sx={{
                    background: " #273142",
                    borderRadius: 2,
                    border: "1px solid #313d4f",
                  }}
                  defaultExpanded={true}
                  className="mt-3"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      height: "20%",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: 24,
                        lineHeight: "20px",
                        fontWeight: 700,
                      }}
                    >
                      Trading Chart
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ minHeight: "100%" }}>
                    {tradingData && selectedTradePair !== undefined && (
                      <ChartConatiner
                        symbol={
                          selectedTradePair?.label || "No tradepairs available"
                        }
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )}

              {selectedComponent.value === "Order Book" &&
                isMobile &&
                tradingData !== null &&
                selectedTradePair?.value !== null &&
                selectedTradePair?.value !== undefined && (
                  <OrderBookConatiner
                    userID={userId}
                    tradePairID={selectedTradePair?.value || null}
                  />
                )}
            </Col>
            <Col md={4} style={{ paddingLeft: 4 }}>
              {!isMobile && (
                <>
                  {" "}
                  <Row className={`${isTablet ? "gap-3" : ""}`}>
                    <Col lg={8} md={12}>
                      <TradePairSelectorComponent
                        selectedRegion={selectedRegion?.value || ""}
                        allTradePairs={
                          tradingData?.map((er) => er?.value) || []
                        }
                        selectedTradePair={selectedTradePair}
                        setSelectedTradePair={setSelectedTradePair}
                      />
                    </Col>
                    <Col lg={4} md={12}>
                      <Label className="mb-0">Select Regions</Label>

                      <CustomDropdown
                        options={options}
                        value={selectedRegion}
                        onChange={(e: any) => {
                          setSelectedRegion(e);
                        }}
                        isDisabled={false}
                      />
                    </Col>
                  </Row>
                  {/* <Row className="mt-3">
                    <Col md={12}>
                      <TradePairSelectorComponent
                        selectedRegion={selectedRegion?.value || ""}
                        allTradePairs={
                          tradingData?.map((er) => er?.value) || []
                        }
                        selectedTradePair={selectedTradePair}
                        setSelectedTradePair={setSelectedTradePair}
                      />
                    </Col>
                  </Row> */}
                </>
              )}

              {(!isMobile || selectedComponent.value === "Recent Trades") && (
                <Row className="mt-3">
                  <Col md={12}>
                    <Accordion
                      sx={{
                        background: " #273142",
                        border: "1px solid #313d4f",
                        borderRadius: 2,
                      }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#ffffff" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          height: "20%",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#ffffff",
                            fontSize: 24,
                            lineHeight: "20px",
                            fontWeight: 700,
                          }}
                        >
                          Recent Trades
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ minHeight: "100%" }}>
                        {tradingData !== null &&
                          userId !== null &&
                          selectedTradePair?.value !== undefined && (
                            <RecentTrades
                              tradeID={tradePairId}
                              TradingData={tradingData}
                              SetTradingData={setTradingData}
                            />
                          )}
                      </AccordionDetails>
                    </Accordion>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              {tradingData !== null &&
                selectedTradePair?.value !== null &&
                selectedTradePair?.value !== undefined && (
                <BuySellContainer
                    userID={userId}
                    tradePairID={selectedTradePair?.value || undefined}
                    tokenIDs={tokenIDs}
                    setTokenIDs={settokenIDs}
                    selectedBuySellMode={selectedBuySellMode}
                    setSelectedBuySellMode={setSelectedBuySellMode}
                  refresh_numbers={refreshNumbers}
                />
                )}
            </Col>
            {!isMobile && (
              <Col
                md={6}
                style={{ paddingLeft: 4 }}
                className={`${isTablet ? "mt-4" : ""}`}
              >
                {tradingData !== null &&
                  selectedTradePair?.value !== null &&
                  selectedTradePair?.value !== undefined && (
                    <OrderBookConatiner
                      userID={userId}
                      tradePairID={selectedTradePair?.value || null}
                    />
                  )}
              </Col>
            )}
          </Row>
          {!role?.startsWith("unverified") && (
            <Row className="mt-3">
              <Col md={12}>
                <Accordion
                  sx={{
                    background: " #273142",
                    border: "1px solid #313d4f",
                    borderRadius: 2,
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      height: "20%",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: 24,
                        lineHeight: "20px",
                        fontWeight: 700,
                      }}
                    >
                      Open Orders
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ minHeight: "100%" }}>
                    {tradingData !== null &&
                      userId !== null &&
                      selectedTradePair?.value !== undefined && (
                        <OpenOrdersContainer
                          tradePairID={selectedTradePair?.value}
                          userID={userId}
                        setRefreshNumbers={setRefreshNumbers}
                        />
                      )}
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
          )}
        </div>
      </ErrorBoundary>
    </SocketConectionProvider>
  );
};

export default Trading;
